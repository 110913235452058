import { Routes, Route } from 'react-router-dom';
import Main from '../../pages/Main/Main';
import AboutUs from '../../pages/AboutUs/AboutUs';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import Leadership from '../../pages/Leadership/Leadership';
// import ProspectiveResidents from '../../pages/Prospective Residents/ProspectiveResidents';
// import QualificationDetails from '../../pages/QualificationDetails/QualificationDetails';
// import QualificationProcess from '../../pages/QualificationProcess/QualificationProcess';
import CurrrentResidents from '../../pages/CurrentResidents/CurrentResidents';
import ContactUs from '../../pages/ContactUs/ContactUs';
import Investors from '../../pages/Investors/Investors';
import AvailableProperties from '../../pages/AvailableProperties/AvailableProperties';
import PetPolicy from '../../pages/PetPolicy/PetPolicy';
import Terms from '../../pages/Terms/Terms';
import PropertyDetails from '../../pages/AvailableProperties/PropertyDetails';
import Management from '../../pages/Management/Management';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path='/' element={<Main />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/leadership" element={<Leadership />} />
        {/* <Route exact path="/prospective-residents" element={<ProspectiveResidents />} />
        <Route exact path="/qualification-details" element={<QualificationDetails />} />
        <Route exact path="/qualification-process" element={<QualificationProcess />} /> */}
        <Route exact path="/current-residents" element={<CurrrentResidents />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/investors" element={<Investors />} />
        <Route exact path="/management" element={<Management />}/>
        <Route exact path="/available-properties" element={<AvailableProperties />} />
        <Route exact path="/pet-policy" element={<PetPolicy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route path="/photos/:propertyId/:projectId" element={<PropertyDetails />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
