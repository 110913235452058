import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../../../images/HMS PM LLC Logotype Green-Peach RGB copy.png"
import { LinkContainer } from 'react-router-bootstrap';
import "./NavBar.css"

function NavBar({setPage}) {
    
    return (
        <Navbar fixed='top' expand="lg">
            <Container fluid id='cont-fluid'>
                <LinkContainer onClick={() => setPage('/')} to="/">
                    <Navbar.Brand><img src={logo} style={{ height: 'inherit' }} /></Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <LinkContainer to="/about-us">
                            <Nav.Link  onClick={() => setPage('/about-us')}>About Us</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/available-properties">
                            <Nav.Link onClick={() => setPage('/available-properties')}>Available Properties</Nav.Link>
                        </LinkContainer>
                        {/* <NavDropdown title="Prospective Residents" className="basic-nav-dropdown">
                            <LinkContainer activeClassName='active' to="/prospective-residents">
                                <NavDropdown.Item>Becoming a Resident</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer activeClassName='active' to="/qualification-process">
                                <NavDropdown.Item>Qualification Process</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer activeClassName='active' to="/qualification-details">
                                <NavDropdown.Item>Qualification Details</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer activeClassName='active' to="/pet-policy">
                                <NavDropdown.Item>Pet Policy</NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer activeClassName='active' to="/available-properties">
                                <NavDropdown.Item>Available Properties</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown> */}
                        <LinkContainer to="/current-residents">
                            <Nav.Link onClick={() => setPage('/current-residents')}>Current Residents</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/contact-us">
                            <Nav.Link onClick={() => setPage('/contact-us')}>Contact Us</Nav.Link>
                        </LinkContainer>
                        {/* <LinkContainer style={{ backgroundColor: '#fa9370', border: 'none' }} to="/investors">
                            <Button size='md' as={Link} to="/investors">Investors</Button>
                        </LinkContainer> */}
                        <NavDropdown title="Work With Us" className="basic-nav-dropdown">
                            <LinkContainer onClick={() => setPage('/investors')}activeClassName='active' to='/investors'>
                                <NavDropdown.Item>Investors</NavDropdown.Item>
                            </LinkContainer>
                            {/* <LinkContainer onClick={() => setPage('/management')}activeClassName='active' to='/management'>
                                <NavDropdown.Item id='property'>Property Management</NavDropdown.Item>
                            </LinkContainer> */}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;