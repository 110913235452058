import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import logger from 'redux-logger';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { createStore, applyMiddleware, combineReducers } from 'redux';

const root = ReactDOM.createRoot(document.getElementById('root'));

const details = (state ={}, action) => {
  switch (action.type) {
    case 'SET_DETAILS':
        return action.payload;
    default:
      return state;
  }
}

const photos = (state = [], action) => {
  switch (action.type) {
    case 'SET_PHOTOS':
      return action.payload;
    default:
      return state;
  }
}

const header = (state = '', action) => {
  switch (action.type) {
    case 'SET_HEADER':
      return action.payload;
    default:
      return state;
  }
}

const storeInstance = createStore(
  combineReducers({
    details,
    photos,
    header
  }),
  applyMiddleware(logger)
)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Provider store={storeInstance}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
