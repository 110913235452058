import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import './Footer.css';
import footerImage from '../../images/HMS-Logotype-Cream-Peach-RGB-1.png';
import fairHousingLogo from '../../images/fair-equal-housing-png-logo-8.png';


function Footer() {


    return (
        <Container fluid id="footer-cont" style={{ paddingTop: '20px' }}>
            <Row>
                <Col id="footer-company-info" md={4} style={{ padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                    <Row>
                        {/* <Col style={{ padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}> */}
                        <h4 id="footer-HS-Name">HomeSource Property Management, LLC</h4>
                        <p className="footer-link" style={{ margin: 0 }}><a href="mailto:info@renthomesource.com">info@renthomesource.com</a></p>
                        <p className="footer-link" style={{ margin: 0 }}><a href="tel:8775044410">(877) 504-4410</a></p>
                        <address>
                            8511 Davis Lake Parkway<br />
                            Suite C6-225<br />
                            Charlotte, NC 28269
                        </address>
                        {/* </Col> */}
                    </Row>
                </Col>
                <Col md={4}  style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                    {/* <Row>
                        <Col xs={3} md={4} />
                        <Col xs={6} md={4} style={{ padding: 0, textAlign: 'center' }}>
                            <img src={footerImage} style={{ maxWidth: 'inherit', paddingBottom: '25px' }} />
                        </Col>
                        <Col xs={3} md={4} />
                    </Row> */}
                    <Row>
                        <ul id="footer-list" style={{  justifyContent: 'center', listStyle: 'none', textAlign: 'center', padding: 0 }}>
                            <li><Link to="/about-us">ABOUT US</Link></li>
                            {/* <li><Link to="/prospective-residents">PROSPECTIVE RESIDENTS</Link></li> */}
                            <li><Link to="available-properties">AVAILABLE PROPERTIES</Link></li>
                            <li><Link to="/current-residents">CURRENT RESIDENTS</Link></li>
                            <li><Link to="/pet-policy">PET POLICY</Link></li>
                            {/* <li><Link to="/qualification-details">QUALIFICATION DETAILS</Link></li>
                            <li><Link to="/qualification-process">QUALIFICATION PROCESS</Link></li> */}
                            <li><Link to="/investors">INVESTMENTS</Link></li>
                            <li><Link to="/leadership">LEADERSHIP</Link></li>
                            <li><Link to="/contact-us">CONTACT</Link></li>
                        </ul>
                    </Row>
                </Col>
                <Col md={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Row id="footer-states" >
                        <p>Alabama - HomeSource Property Management LLC - License 000158306-0</p>
                        <p>Arkansas - HomeSource Property Management, LLC - Liense PB00068721</p>
                        <p>Georgia - HomeSource Property Management (DE) LLC - License 80820</p>
                        <p>Mississippi - HomeSource Property Management LLC - License 24130</p>
                        <p>North Carolina - HomeSource Property Management, LLC - License C38195</p>
                        <p>Oklahoma - HomeSource Property Management, LLC - License 206337</p>
                        <p>Tennesse - HomeSource Property Management LLC - License 266186</p>
                        <p style={{ marginBottom: 0 }}>Texas - HomeSource Property Management, LLC - License 9014032</p>
                    </Row>
                </Col>
            </Row>
            <hr />
            <Row style={{ paddingBottom: '10px' }}>
                <Col style={{ textAlign: 'center' }}>
                    <p style={{ textAlign: 'center', margin: 0, fontSize: '12px' }}>HomeSource Operations, LLC<sup>TM</sup> | 8615 Cliff Cameron Drive | Suite 200 | Charlotte, NC 28269</p>
                    <p style={{ textAlign: 'center', fontSize: '12px', margin: 0 }}>HomeSource Property Management, LLC<sup>TM</sup> is a wholly owned subsidiary of HomeSource Operations, LLC.<sup>TM</sup> </p>
                    <p style={{ padding: 0, textAlign: 'center', margin: 0, fontSize: '12px' }}>© 2023 HomeSource Operations, LLC. All rights reserved. </p>
                    <Link id="terms" to="/terms" style={{ fontSize: '12px' }}>Terms of Use</Link>
                </Col>
            </Row>
            <Row style={{ paddingBottom: '20px' }}>
                <Col style={{ textAlign: 'center' }}>
                    <a href="https://www.hud.gov/program_offices/fair_housing_equal_opp/aboutfheo" target="_blank"><img src={fairHousingLogo} style={{ width: '75px', height: 'auto', margin: '0 auto', textAlign: 'center' }} /></a>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer;