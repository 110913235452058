import { Modal, Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Redirect({ show, setShow, url }) {

    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Alert style={{ margin: 0, backgroundColor: '#F5EEDF', border: '1px solid #fa9370', color: '#003A40' }}>
                <Alert.Heading>Redirect Notice</Alert.Heading>
                <p>
                    You are about to be redirected to our partner's website. Select continue below to be redirected, otherwise select cancel to remain on our site.
                </p>
                <hr />
                <div className="d-flex justify-content-between">
                    <Button onClick={() => setShow(false)} variant="danger">
                        Cancel
                    </Button>
                    <Button
                        as={Link}
                        target="_blank"
                        to={url}
                        variant="success"
                        onClick={() => setShow(false)}
                    >
                        Continue to BeHome247
                    </Button>
                </div>
            </Alert>
        </Modal>
    );
}

export default Redirect;