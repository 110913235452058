import { Container, Row, Col } from 'react-bootstrap';

function Terms() {

    return (
        <Container fluid style={{ padding: 0, marginTop: '56px', flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row className="header-row" style={{ margin: 0 }}>
                <div id="leadership-header" style={{ backgroundColor: '#003A40' }} />
                <span id="leadership-overlay" style={{ color: '#F5EEDF' }}><h1><span>Terms of Use</span></h1></span>
            </Row>
            <Row className='section-row'>
                <Col sm={2} xxl={3} />
                <Col sm={8} xxl={6}>
                    <Row>
                        <h2 style={{ textAlign: 'left' }}>
                            Welcome to HomeSource Operations LLC (“HomeSource”). These terms and conditions
                            outline the rules and regulations for the use of the HomeSource Website.
                        </h2>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            By accessing this website we assume you accept these terms and conditions in full.
                            Do not continue to use the HomeSource website if you do not accept all of the terms
                            and conditions stated on this page.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            The following terminology applies to these Terms and Conditions,
                            Privacy Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and
                            “Your” refers to you, the person accessing this website and accepting the Company's terms
                            and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company.
                            “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or
                            ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
                            the process of our assistance to the Client in the most appropriate manner, whether by formal meetings
                            of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect
                            of provision of the Company's stated services/products, in accordance with and subject to, prevailing
                            law of. Any use of the above terminology or other words in the singular, plural, capitalization and/or
                            he/she or they, are taken as interchangeable and therefore as referring to same.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            Unless otherwise stated, HomeSource and/or its licensors own the intellectual property
                            rights for all material on the HomeSource website. All intellectual property rights are reserved.
                            You may view and/or print pages from https://renthomesource.azurewebsites.net/ for your own personal
                            use subject to restrictions set in these terms and conditions. You must not:
                        </p>
                    </Row>
                    <Row>
                        <ul style={{ textAlign: 'left' }}>
                            <li>Republish material from https://renthomesource.azurewebsites.net/</li>
                            <li>Sell, rent or sub-license material from https://renthomesource.azurewebsites.net/</li>
                            <li>Reproduce, duplicate, or copy material from https://renthomesource.azurewebsites.net/</li>
                            <li>Redistribute content from HomeSource Operations LLC (unless content is specifically made for redistribution).</li>
                        </ul>
                    </Row>
                    <Row>
                        <p>
                            The following organizations may link to our website without prior written approval:
                        </p>
                    </Row>
                    <Row>
                        <ul style={{ textAlign: 'left' }}>
                            <li>Government agencies</li>
                            <li>Search engines</li>
                            <li>News organizations</li>
                        </ul>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            Online directory distributors when they list us in the directory may link to our website
                            in the same manner as they hyperlink to the website of other listed businesses; and Systemwide
                            Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and
                            charity fundraising groups whichmay not hyperlink to our website. These organizations may link
                            to our home page, to publications or to other website information so long as the link: (a) is
                            not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of
                            the linking party and its products or services; and (c) fits within the context of the linking party's site.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            We may consider and approve in our sole discretion other link requests from the following types
                            of organizations: commonly-known consumer and/or business information sources such as Chambers of
                            Commerce, American Automobile Association, AARP and Consumers Union; dot.com community sites;associations
                            or other groups representing charities, including charity giving sites, online directory distributors;
                            internet portals; accounting, law and consulting firms whose primary clients are businesses; and
                            educational institutions and trade associations.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            We will approve link requests from these organizations if we determine that: (a) the link would not
                            reflect unfavorably on us or our accredited businesses (for example, trade associations or other
                            organizations representing inherently suspect types of business, such as work-at-home opportunities,
                            shall not be allowed to link); (b) the organization does not have an unsatisfactory record with us;
                            (c) the benefit to usfrom the visibility associated with the hyperlink outweighs the absence of ; and
                            (d) where the link is in the context of general resource information or is otherwise consistent with
                            editorial content in a newsletter or similar product furthering the mission of the organization.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            These organizations may link to our home page, to publications or to other website information so
                            long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement
                            or approval of the linking party and it products or services; and (c) fits within the context of the
                            linking party's site.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            If you are among the organizations listed above and are interested in linking to our website, you must
                            notify us by sending an email to info@renthomesource.azurewebsites.net. Please include your name, your
                            organization name, contact information (such as a phone number and/or e-mail address) as well as the URL
                            of your site, a list of any URLs from which you intend to link to our website, and a list of the URL(s)
                            on our site to which you would like to link. Allow 2-3 weeks for a response.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            Approved organizations may hyperlink to our website as follows:
                        </p>
                    </Row>
                    <Row>
                        <ul style={{ textAlign: 'left' }}>
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator (web address) being linked to; or</li>
                            <li>
                                By use of any other description of our website or material being linked to that makes sense
                                within the context and format of content on the linking party's site.
                            </li>
                        </ul>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            No use of the HomeSource logo or other artwork will be allowed for linking absent a trademark
                            license agreement. We reserve the right at any time and in its sole discretion to request that you
                            remove all links or any particular link to our website. You agree to immediately remove all links to
                            our website upon such request. We also reserve the right to amend these terms and conditions and its
                            linking policy at any time. By continuing to link to our website, you agree to be bound to and abide
                            by these linking terms and conditions. If you find any link on our website or any linked website
                            objectionable for any reason, you may contact us about this. We will consider requests to remove links
                            but will have no obligation to do so or to respond directly to you. Whilst we endeavor to ensure that
                            the information on this website is correct, we do not warrant its completeness or accuracy; nor do we
                            commit to ensuring that the website remains available or that the material on the website is kept up to
                            date. We shall have no responsibility or liability for any content appearing on your website. You agree
                            to indemnify and defend us against all claims arising out of or based upon your website. No link(s) may
                            appear on any page on your website or within any context containing content or materials that may be
                            interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the
                            infringement or other violation of, any third-party rights.
                        </p>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
                            relating to our website and the use of this website (including, without limitation, any warranties implied by
                            law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).
                            Nothing in this disclaimer will:
                        </p>
                    </Row>
                    <Row>
                        <ul style={{ textAlign: 'left' }}>
                            <li>limit or exclude our or your liability for death or personal injury resulting from negligence</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                        </ul>
                    </Row>
                    <Row>
                        <p style={{ textAlign: 'justify' }}>
                            The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
                            are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in
                            relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
                            (including negligence) and for breach of statutory duty. To the extent that the website and the information
                            and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                        </p>
                    </Row>
                </Col>
                <Col sm={2} xxl={3} />
            </Row>
            <Row className='section-row'>
                <Col sm={2} xxl={3} />
                <Col sm={8} xxl={6}>
                    <Row>
                        <h2 style={{ textAlign: 'center' }}>Self Tour Terms & Conditions</h2>
                        <h5 style={{ textAlign: 'center' }}>Access and Tour of a home managed by HomeSource Property Management, LLC</h5>
                    </Row>
                    <Row>
                        <p>
                            Our Services allow you to access, and self-tour homes managed by HomeSource Property Management,
                            LLC. By using the Services to access a home, you agree and acknowledge that you are entering that home
                            for the sole purpose of evaluating the home for the potential rental and for no other purposes, and that you
                            are at least eighteen (18) years of age. In addition to the foregoing, when touring a Home, <strong>YOU AGREE NOT TO:</strong>
                        </p>
                    </Row>
                    <Row>
                        <ol>
                            <li>
                                Violate any applicable law, ordinances, rules or regulations, including,
                                but not limited to, those imposed by a homeowner's association.
                            </li>
                            <li>
                                Violate any local health and safety guidance or applicable stay at home orders.
                            </li>
                            <li>
                                Enter the home in any manner other than through the front door,
                                except as may be authorized by the HomeSource Property Management.
                            </li>
                            <li>
                                Smoke (or vape), consume or be under the influence of any drugs, alcohol,
                                or other illegal substance(s) while at the home.
                            </li>
                            <li>
                                Bring any actual or perceived weapon(s) into the home, such as firearms,
                                knives or any other object that is perceived to be commonly used to cause death or bodily injury.
                            </li>
                            <li>
                                Bring any animals (other than service animals) into the home.
                            </li>
                            <li>
                                With the exception of any informational or marketing materials provided by the HomeSource Property Management, take or remove any property, fixture, and/or item from the home.
                            </li>
                            <li>
                                Use any fixtures, appliances, or facilities in the home, including showers, pools, bathrooms, washer and dryer, fridge, and sink, for any purpose unrelated to the home tour.                            </li>
                            <li>
                                Make any alterations, additions, and/or improvements to the home, and/or cause any damage or loss to the home.
                            </li>
                            <li>
                                Turn-off, deactivate, or alter any security system installed at the home.
                            </li>
                            <li>
                                Endanger, interfere, disturb or cause harm to any other person, including neighbors.
                            </li>
                            <li>
                                Engage in any excessively loud activities or activities that would create a disturbance or nuisance to the neighbors.
                            </li>
                            <li>
                                Leave open any doors, windows, or gates upon completion of the tour.
                            </li>
                            <li>
                                Tour the home for more than one hour.
                            </li>
                            <li>
                                Interfere with the tour of anyone else in the home; and
                            </li>
                            <li>
                                Commit any waste or dispose of any garbage or personal property at or around the home.
                            </li>
                        </ol>
                    </Row>
                    <Row>
                        <p>
                            By entering the Home, you agree: (i) to be responsible for and to reimburse HomeSource Property Management for any
                            damage that you (including anyone that accompanies you) cause to the home, including but not limited to any damage to any fixtures,
                            equipment, and/or appliances; and (ii) to the fullest extent permitted by law, to hold the HomeSource Property Management harmless from any
                            and all liability, claims, and/or injuries arising from or in connection with your (including anyone that accompanies you) use and access to the home,
                            unless caused by the gross negligence or willful misconduct of HomeSource Property Management.
                        </p>
                    </Row>
                    <Row>
                        <p>
                            In the event that you violate or are in breach of any of the foregoing rules and regulations, HomeSource Property 
                            Management reserves the right, in their sole discretion, to prohibit you from using the Services and/or accessing 
                            any HomeSource managed Home in the future.
                        </p>
                    </Row>
                </Col>
                <Col sm={2} xxl={3} />
            </Row>
        </Container>

    )
}

export default Terms;