import { useState } from 'react';
import NavBar from './NavBar/NavBar';
import HeaderImage from './HeaderImage';
import './Header.css';

function Header() {
    const [page, setPage] = useState('/');

    return (
        <header style={{ backgroundColor: 'white', marginTop: '56px' }}>
            <NavBar
                setPage={setPage}
            />
            <HeaderImage
                page={page}
                setPage={setPage}
            />
        </header>
    )
}

export default Header;