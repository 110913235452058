import { Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import current1 from '../../images/tierra-mallorca-rgJ1J8SDEAY-unsplash.jpg';
import current2 from '../../images/HS-current-vector-min.png';
import current3 from '../../images/HS-current-payrent-min.png';
import current4 from '../../images/HS-current-maintenance-min.png';
import Redirect from "../../components/Redirect/Redirect";

function CurrrentResidents() {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('');

    const displayModal = option => {
        option === 'maint' ? setUrl('https://homesource.behome247.com/app/services/work-orders') : setUrl('https://homesource.behome247.com/app/billing/balance');
        setShow(true);
    }

    return (
        <Container fluid style={{ padding: 0, flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row className="inner" style={{ margin: 0 }}>
                <Col lg={3} />
                <Col lg={6}>
                    <Row>
                        <h2>We're here to help.</h2>
                    </Row>
                    <Row>
                        <p>
                            We continue to streamline our processes to ensure we add value every day.
                            Don't sweat the small stuff. Together, we make a house your home.
                        </p>
                        <p>
                            Follow the links below to pay your rent or send a maintenance request.
                        </p>
                    </Row>
                </Col>
                <Col lg={3} />
            </Row>
            <Row className="section-row">
                <Col xl={2} />
                <Col xl={8}>
                    <Row>
                        <Col sm={5} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '20px'}}>
                            <img src={current3} style={{ maxWidth: 'inherit' }} />
                            <h2 style={{ textAlign: 'center' }}>Pay your rent.</h2>
                            <h5 style={{ textAlign: 'center' }}>Process transactions online or set up monthly payments.</h5>
                            <Button
                                size="lg"
                                style={{ backgroundColor: '#fa9370', border: 'none' }}
                                onClick={() => displayModal('rent')}
                            >
                                LOGIN NOW
                            </Button>
                        </Col>
                        <Col sm={2} />
                        <Col sm={5} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginBottom: '20px' }}>
                            <img src={current4} style={{ maxWidth: 'inherit' }} />
                            <h2 style={{ textAlign: 'center' }}>Send maintenance request.</h2>
                            <h5 style={{ textAlign: 'center' }}>We're here to provide you with reliable, timely service.</h5>
                                    <Button
                                        size="lg"
                                        style={{ backgroundColor: '#fa9370', border: 'none'}}
                                        onClick={() => displayModal('maint')}
                                    >
                                        LOGIN NOW
                                    </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xl={2} />
            </Row>
            <Redirect
                show={show}
                setShow={setShow}
                url={url}
            />
        </Container>
    )
}

export default CurrrentResidents;