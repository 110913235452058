import { Container, Row, Col } from "react-bootstrap";
import leadership1 from '../../images/investment01.png';
import leadership2 from '../../images/Randy-1-2-1529x2048.jpg';
import leadership3 from '../../images/Adam-1-2-1529x2048.jpg';
import leadership4 from '../../images/Rich-1-2-1529x2048.jpg';

function Leadership() {

    return (
        <Container fluid style={{ padding: 0, marginTop: '56px', flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row className="inner" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <h2>A deep understanding of SFR investments.</h2>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p style={{ marginBottom: 0 }}>
                                Our leadership team founded HomeSource to capitalize on the opportunities they saw in the SFR real estate space.
                                The team's collective knowledge and insight informs our investment thesis,
                                which drives strategic investments in communities poised for growth.
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={2} />
                        <Col className="hide-mobile" md={8}>
                            <img src={leadership1} style={{ maxWidth: 'inherit' }} />
                        </Col>
                        <Col md={2} />
                    </Row>
                </Col>
            </Row>
           
            <Row className="inner" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <img src={leadership3} />
                        </Col>
                        <Col md={2} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <hr />
                        <Row style={{ marginBottom: '20px' }}>
                            <h2>ADAM LEVINSON</h2>
                            <h6>CIO & CO-FOUNDER</h6>
                        </Row>
                        <Col md={6}>
                            <Row>
                                <Col md={10}>
                                    <p>
                                        Mr. Levinson is an industry pioneer in the single-family rental industry. During his career, he has acquired,renovated,
                                        and sold over 15,000 homes and played a key role in the acquisition of over $1B in assets.
                                        Prior to HomeSource, he co-founded Street View Properties, a fund focused on acquiring assets below replacement cost in target markets
                                        and repositioning those assets to sell via the retail channel to end users and/or investors.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row></Row>
                            <Row><div></div></Row>
                            <Row>
                                <Col md={10}>
                                    <Row>
                                        <p>
                                            Prior to Street View, Mr. Levinson led a nationwide acquisition and disposition strategy for Tricon American Homes.
                                            As one of the founding partners under a joint venture arrangement with Tricon Capital (TSX: TCN),
                                            he oversaw the growth of the portfolio and was key to the national growth strategy of Tricon American Homes.
                                            A previous venture, South Florida Residential Homes, acquired and leased over 1,800 single-family homes and 500 multifamily units,
                                            which eventually contributed to Tricon American Homes as part of a roll-up at inception of the joint venture.
                                        </p>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="section-row" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <img src={leadership4} />
                        </Col>
                        <Col md={2} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <hr />
                        <Row style={{ marginBottom: '20px' }}>
                            <h2>RICH SCOLA</h2>
                            <h6>COO & CO-FOUNDER</h6>
                        </Row>
                        <Col md={6}>
                            <Row>
                                <Col md={10}>
                                    <p>
                                        Mr. Scola has been involved in the single-family rental space for over a decade, in a variety of leadership roles.
                                        Prior to HomeSource, he co-founded Street View Properties, a fund focused on acquiring assets below replacement cost in target markets
                                        and then repositioning those assets to sell via the retail channel to end-user owner-occupants and/or investors.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row></Row>
                            <Row><div></div></Row>
                            <Row>
                                <Col md={10}>
                                    <Row>
                                        <p>
                                            Prior to Street View, Mr. Scola had several key roles with Tricon American Homes. 
                                            As one of the founding partners under a joint venture arrangement with Tricon Capital (TSX:TCN), 
                                            he served as Vice President of Dispositions and as Vice President of Operations, 
                                            leading major initiatives that informed strategy, helped scale the business, 
                                            and developed a high-efficient property management platform. He was the co-founder of South Florida Residential Partners (SFRP), 
                                            a venture that acquired, leased, and renovated over 1,800 single-family homes which were eventually contributed to 
                                            Tricon American Homes as part of a roll-up at inception of the joint venture.
                                        </p>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Leadership;