import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import PropertyFilters from "./PropertyFilters";
import { Loader } from '@progress/kendo-react-indicators';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BiCube, BiBed, BiBath } from 'react-icons/bi';
import { TbRulerMeasure } from 'react-icons/tb';
import availableHeader from "../../images/HS-about-footerbg-min.png";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AvailableProperties() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [query, setQuery] = useState('');
    const [beds, setBeds] = useState(1);
    const [baths, setBaths] = useState(1);
    const [properties, setProperties] = useState([]);
    const [filterResults, setFilterResults] = useState([]);
    const [states, setStates] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [loading, setLoading] = useState(false);
    const details = useSelector(store => store.details);
    const [value, setValue] = useState([500, 5000]);
    const [rentValue, setRentValue] = useState([1000, 3000])
    const ref = useRef(null);

    const getProperties = async () => {
        setLoading(true);
        axios.get('/properties').then((response) => {
            setLoading(false);
            setProperties(response.data);
            console.log(response.data)
        }).catch(error => {
            console.log(`Error in GET ${error}`);
            alert('Something went wrong.');
        });
    }

    const seeDetails = property => {

        axios.get(`/api/photos/${property.HSPropertyId}/${property.projectId}`).then(response => {
            dispatch({ type: 'SET_DETAILS', payload: property });
            dispatch({ type: 'SET_PHOTOS', payload: response.data });
            navigate(`/photos/${property.HSPropertyId}/${property.projectId}`);
        }).catch(error => {
            console.log(`Error getting property details:`, error);
            alert('Someting went wrong.');
        });
    }

    function handleClick() {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    }

    const statesFilter = initialProperties => {
        const filteredStates = initialProperties.filter(property => states.indexOf(property.StateAbbr) >= 0);
        return filteredStates;
    }

    const footageFilter = initialProperties => {
        const filteredFootage = initialProperties.filter(property => property.Sqft > value[0] && property.Sqft < value[1]);
        return filteredFootage;
    }

    const textSearchFilter = initialProperties => {
        const filteredText = initialProperties.filter(property => (property.Street.concat(" ", property.CityName, " ", property.StateAbbr, ", ", property.StateAbbr, property.Zip)).toLowerCase().includes(query));
        return filteredText;
    }

    const bedsFilter = initialProperties => {
        const filteredBeds = initialProperties.filter(property => property.Beds >= beds);
        return filteredBeds;
    }

    const bathsFilter = initialProperties => {
        const filteredBaths = initialProperties.filter(property => property.Baths >= baths);
        return filteredBaths;
    }

    const rentFilter = initialialProperties => {
        const filteredRent = initialialProperties.filter(property => property.AdvertisedRent > rentValue[0] && property.AdvertisedRent < rentValue[1]);
        return filteredRent;
    }

    const clearFilters = () => {
        setQuery('');
        setBeds(1);
        setBaths(1);
        setValue([500, 5000]);
        setRentValue([1000, 3000]);
        setFilterResults(properties);
    }

    useEffect(() => {
        getProperties();
    }, [])

    useEffect(() => {
        const filteredStates = statesFilter(properties);
        const filteredFootage = footageFilter(filteredStates);
        const filteredRent = rentFilter(filteredFootage);
        const filteredText = textSearchFilter(filteredRent);
        const filteredBeds = bedsFilter(filteredText);
        const filteredBaths = bathsFilter(filteredBeds);

        setFilterResults(filteredBaths);
    }, [query, beds, baths, states, value, rentValue]);

    return (
        <Container fluid style={{ padding: 0 }}>
            <PropertyFilters
                beds={beds}
                setBeds={setBeds}
                baths={baths}
                setBaths={setBaths}
                setQuery={setQuery}
                properties={properties}
                setFiltered={setFiltered}
                setStates={setStates}
                states={states}
                filtered={filtered}
                value={value}
                setValue={setValue}
                rentValue={rentValue}
                setRentValue={setRentValue}
                clearFilters={clearFilters}
            />
            <div ref={ref} />
            <Row className="section-row" style={{ display: 'flex', flexWrap: 'wrap', margin: 0 }}>
                {loading ? <Loader
                    size="large"
                    type="infinite-spinner"
                    style={{ color: '#fa9370', display: 'flex', justifyContent: 'center' }}
                /> :
                    (filtered === false ? properties : filterResults).map(property => (
                        <Col xs={12} md={6} lg={4} xxl={3} key={property.HSPropertyId} style={{ display: 'flex', alignItems: 'stretch', padding: '0 5px' }}>
                            <Card onClick={() => seeDetails(property)} style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', height: 'inherit', marginBottom: '10px' }}>
                                <Card.Img variant="top" src={property.url} style={{ width: 'auto', margin: 0 }} />
                                <Card.Body style={{ backgroundColor: '#F5EEDF', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Card.Title style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>{property.Street}</Card.Title>
                                    <Card.Text>{property.CityName}, {property.StateAbbr} {property.Zip}</Card.Text>
                                    <Card.Text>
                                        <div>
                                            <span><strong>${property.AdvertisedRent}</strong>/mo</span>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span><BiBed /> {property.Beds}</span>
                                            <span><BiBath /> {property.Baths}</span>
                                            <span><TbRulerMeasure /> {property.Sqft} Sqft</span>
                                            <span><BiCube /> 3D Tour</span>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    );
}

export default AvailableProperties;