import { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import homeHeader from "../../images/matt-donders-boz4mBOeR2U-unsplash.jpg"
import aboutHeader from '../../images/HS-about-banner-min-e1649686863430.png';
import availableHeader from "../../images/HS-about-footerbg-min.png";
import currentHeader from '../../images/tierra-mallorca-rgJ1J8SDEAY-unsplash.jpg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from "@mui/material";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function HeaderImage({ page, setPage }) {
    const [img, setImg] = useState('');
    const [url, setUrl] = useState('/');
    const ref = useRef(null);

    function handleClick() {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const Header = () => {
        switch (url) {
            case '/':
                return (
                    <Row style={{ maxWidth: '100vw' }} id="header-img-main">
                        <img src={homeHeader} alt="header image" />
                        <div id="overlay-main">
                            <h1>HomeSource</h1>
                            <h5>Discover Your Perfect Home</h5>
                        </div>
                        <div style={{ position: 'absolute', bottom: 0, paddingBottom: '20px' }}>
                            <Link to="/available-properties"><Button onClick={() => setPage('/available-properties')} id="home-properties-btn" size="lg" style={{ backgroundColor: '#dfece4', border: 'none', color: '#003A40' }}>View Our Properties</Button></Link>
                        </div>
                    </Row>
                )
            case '/about-us':
                return (
                    <Row id="header-img">
                        <img src={aboutHeader} alt="header image" />
                        <div className="overlay">
                            <h1>Comfort. Service.</h1>
                            <h1>Reliability.</h1>
                        </div>
                    </Row>
                )
            case '/available-properties':
                return (
                    <Row id="header-img">
                        <img src={availableHeader} alt="header image" />
                        <div className="overlay">
                            <h1>Available Properties</h1>
                        </div>
                        {/* <IconButton disableRipple="true" size="large" id="detail-overlay" onClick={handleClick}>
                            <ExpandMoreIcon id="arrow" />
                        </IconButton> */}
                    </Row>
                )
            case '/current-residents':
                return (
                    <Row id="header-img">
                        <img src={currentHeader} alt="header image" />
                        <div className="overlay">
                            <h1>Current Residents</h1>
                        </div>
                    </Row>
                )
            case '/contact-us':
                return <></>
                // return (
                //     <Row className="header-row" style={{ margin: 0 }}>
                //         <div id="leadership-header" style={{ backgroundColor: '#332f21' }} />
                //         <span id="leadership-overlay" style={{ color: '#F5EEDF' }}><h1>Contact Us</h1></span>
                //     </Row>)
            case '/investors':
                return (
                    <Row className="header-row" style={{ margin: 0 }}>
                        <div id="investment-header" style={{ backgroundColor: '#332f21' }} />
                        <div id="investment-overlay" style={{ color: '#F5EEDF' }}>
                            <h1>A Strategy That Delivers Value</h1>
                            <h5>Better lives, better neighborhoods, better returns</h5>
                        </div>
                    </Row>
                )
            case '/leadership':
                return (
                    <Row className="header-row" style={{ margin: 0 }}>
                        <div id="leadership-header" style={{ backgroundColor: '#332f21' }}/>
                        <div id="leadership-overlay" style={{ color: '#F5EEDF' }}>
                            <h1>Leadership Matters</h1>
                            <h5>Strategic investment, backed by experience</h5>
                        </div>
                    </Row>
                )
            case '/management': 
                return (
                    <Row className="header-row" style={{ margin: 0 }}>
                        <div id="investment-header" style={{ backgroundColor: '#332f21' }} />
                        <div id="investment-overlay" style={{ color: '#F5EEDF' }}>
                            <h1>Let Us Manage Your Properties</h1>
                            <h5>Driven By Experience</h5>
                        </div>
                    </Row>
                )
        }
    }

    useEffect(() => {
        setUrl(window.location.pathname);
        Header();
    }, [page]);

    return (
        <Header />
    )
}

export default HeaderImage;