import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import contact from '../../images/Homesource-Illustration-ContactUs.png';
import { useState } from "react";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { Loader } from '@progress/kendo-react-indicators';
import LoadingOverlay from 'react-loading-overlay-ts';

function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const [category, setCategory] = useState('');
    const [show, setShow] = useState(false);
    const [type, setType] = useState('');
    const [isActive, setActive] = useState(true)

    const sendForm = event => {
        event.preventDefault();
        setShow(true);
        setType('loading');
        const messageInfo = {
            from: email,
            name: name,
            text: text,
            category: category
        };

        axios.post('/contact', messageInfo).then(response => {
            console.log(response);
            document.getElementById('name-field').value = '';
            document.getElementById('email-field').value = '';
            document.getElementById('text-field').value = '';
            setType('success');
            // alert('Your response has been submitted.');
        }).catch(error => {
            console.log(`Error in POST ${error}`);
            setType('error');
        });
    }

    return (
        <Container fluid style={{ padding: 0, marginTop: '56px', flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row className="inner">
                <h2 className="special">We are here to answer your questions!</h2>
                <p className="special">Please fill out the contact form below and we will get back to you as soon as we can.</p>
            </Row>

            <Row className="section-row">
                <Col md={4} />
                <Col md={4}>
                    <Form onSubmit={(sendForm)}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                onChange={event => setName(event.target.value)}
                                id='name-field'
                                type="text"
                                placeholder="John Doe"
                            />
                            <Form.Text>Enter your first and last name</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Category</Form.Label>
                            <Form.Select
                                defaultValue="Current/ProspectiveResident"
                                onChange={event => setCategory(event.target.value)}
                            >
                                <option value="Current/Prospective Resident">Current/Prospective Resident</option>
                                <option value="Vendor">Vendor</option>
                                <option value="Other">Other</option>

                            </Form.Select>
                            <Form.Text>Select which category best describes you</Form.Text>
                            {/* <Form.Control onChange={event => setName(event.target.value)} id='name-field' type="text" placeholder="John Doe" /> */}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control onChange={event => setEmail(event.target.value)} id='email-field' type="email" placeholder="johndoe@example.com" />
                            <Form.Text>Enter an email address that we may reply to</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Comments</Form.Label>
                            <Form.Control onChange={event => setText(event.target.value)} id='text-field' as="textarea" rows={4} placeholder="Comments" />
                            <Form.Text>Type your comments here and click submit when finished</Form.Text>
                        </Form.Group>
                        <Button
                            type="submit"
                            size="lg"
                            style={{ backgroundColor: '#fa9370', border: 'none' }}
                        > Submit
                        </Button>
                    </Form>
                </Col>
                <Col md={4} />
                {/* <Col md={4}>
                    <Row>
                        <img className="hide-mobile" src={contact} style={{ maxWidth: 'inherit' }} />
                    </Row>
                </Col> */}
            </Row>
            <Modal
                show={show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={type === 'loading' ? 'loader' : 'message'}
            >
                {
                    type !== 'loading' ?
                        type === 'success' ?

                            <Alert style={{ margin: 0 }} variant="success">
                                <Alert.Heading>Success!</Alert.Heading>
                                <p>
                                    Your response has been submited.
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => setShow(false)} variant="outline-success">
                                        Close
                                    </Button>
                                </div>
                            </Alert>

                            :

                            <Alert style={{ margin: 0 }} variant="danger">
                                <Alert.Heading>Error</Alert.Heading>
                                <p>
                                    Your form was unable to be submitted at this time. Please try again later.
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => setShow(false)} variant="outline-danger">
                                        Close
                                    </Button>
                                </div>
                            </Alert>

                        :

                        <LoadingOverlay
                            active={isActive}
                            spinner={<Loader size="large" type="infinite-spinner" />}
                        />
                }
            </Modal>
        </Container>
    )
}

export default ContactUs;