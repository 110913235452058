import homeHeader from "../../images/andrew-wise-LE7RC3l5EeY-unsplash (1).jpg"
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Main.css'
import home2 from "../../images/paige-cody-bOVZ_f3fbQM-unsplash.jpg"
import home4 from '../../images/j-king-ebuixpviQH0-unsplash (1).jpg'
import home5 from '../../images/sigmund-OV44gxH71DU-unsplash.jpg'
import home6 from '../../images/pexels-yan-krukau-8867429.jpg'
import home7 from '../../images/HS-home-welldesigned-min.png'
import Button from "react-bootstrap/Button";

function Main() {


    return (
        <Container fluid style={{ padding: 0, flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row className="section-row" style={{ backgroundColor: '#dfece4', margin: 0, display: 'flex', alignItems: 'center' }}>
                <Col id="main-section-2" md={6} style={{ marginBottom: '50px' }}>
                    <h2>There's No Place Like Home.</h2>
                    <Col xl={10}>
                        <p>HomeSource offers a range of high-quality, single family rental homes in the Southeastern United States, featuring diverse pricing options and floor plans tailored to residents' preferences.</p>
                    </Col>
                    <Col xl={2} />
                </Col>
                <Col md={6}>
                    <img className="content-img" src={home2} style={{ maxWidth: 'inherit', margin: 0 }} />
                </Col>
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col xl={3} />
                <Col xl={6}>
                    <h2 style={{ textAlign: 'center' }}>The HomeSource Promise.</h2>
                    <p style={{ textAlign: 'center' }}>
                        When you choose to lease a home from HomeSource, you gain something invaluable: peace of mind.
                        Our commitment is to ensure your satisfaction every step of the way. Whether you have a maintenance
                        request, need assistance, or have inquiries, we're readily available. We are dedicated to
                        helping you find the perfect home that suits your needs.
                    </p>
                </Col>
                <Col xl={3} />
            </Row>
            <Row className="inner" style={{ margin: 0, paddingTop: '50px' }}>
                <Col md={6}>
                    <Row>
                        <img className="content-img" src={home4} style={{ maxWidth: 'inherit', padding: 0 }} />
                    </Row>
                </Col>
                <Col md={2} style={{ textAlign: 'left' }}>
                    <Row>
                        <h5>PRIME LOCATIONS</h5>
                    </Row>
                    <Row>
                        <p>Conveniently located near schools, job opportunities, and essential amenities. </p>
                    </Row>
                </Col>
                <Col xs={0} md={4} />
            </Row>
            <Row className="inner middle" style={{ margin: 0 }}>
                <Col xs={0} md={4} />
                <Col xs={0} md={2}>
                    <Row>
                        <h5>A HOME TO FIT ANY LIFESTYLE</h5>
                    </Row>
                    <Row>
                        <p>Options that appeal to families, couples, and young professionals.</p>
                    </Row>
                </Col>
                <Col md={6} style={{ textAlign: 'left' }}>
                    <img className="content-img" src={home5} style={{ maxWidth: 'inherit' }} />
                </Col>
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col >
                            <img className="content-img" src={home6} style={{ maxWidth: 'inherit' }} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} style={{ textAlign: 'left' }}>
                    <Row>
                        <h5>TOP-TIER CUSTOMER SERVICE</h5>
                    </Row>
                    <Row>
                        <p>Courtesy of our seasoned team of property management experts.</p>
                    </Row>
                </Col>
                <Col xs={0} md={4} />
            </Row>
            <Row className="inner" id="main-last" style={{ margin: 0 }}>
                <Col xs={0} md={4} />
                <Col xs={0} md={2}>
                    <Row>
                        <h5>WELL-DESIGNED, QUALITY HOMES</h5>
                    </Row>
                    <Row>
                        <p>Explore our contemporary floor plans and finishes.</p>
                    </Row>
                </Col>
                <Col md={6} style={{ textAlign: 'left' }}>
                    <img className="content-img" src={home7} style={{ maxWidth: 'inherit' }} />
                </Col>
            </Row>
        </Container>
    )
}

export default Main;