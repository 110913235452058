import { Container, Row, Col } from 'react-bootstrap';
import investor1 from '../../images/investment01.png';
import investor2 from '../../images/pexels-binyamin-mellish-186077-1-1.jpg';
import investor3 from '../../images/pexels-binyamin-mellish-186077-4.jpg';
import investor4 from '../../images/Homesource-Illustration-Investments2-1.png';
import investor5 from '../../images/Homesource-Illustration-Investments1.png';
import investor6 from '../../images/pexels-agung-pandit-wiguna-1128316-1.jpg';
import investor7 from '../../images/HomeSource_USmap green (1).png';

function Investors() {

    return (
        <Container fluid style={{ padding: 0, flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row className="inner" style={{ margin: 0 }}>
                <Col lg={3} />
                <Col md={6}>
                    <h2 style={{ textAlign: 'center' }}>Investing in exemplary communities.</h2>
                    <p style={{ textAlign: 'center' }}>
                        It takes keen insight and depth of experience to recognize
                        true growth opportunities. <br /> Our leadership team has both.
                    </p>
                </Col>
                <Col lg={6} />
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col md={2} />
                        <Col md={10}>
                            <img src={investor2} style={{ maxWidth: 'inherit' }} />
                        </Col>
                    </Row>

                </Col>
                <Col md={3}>
                    <Row>
                        <h5>
                            Our sophisticated investment strategy hinges on a deep understanding of the single-family
                            rental industry and the many ways we can align capital expectations with social responsibility.
                        </h5>
                    </Row>
                </Col>
                <Col xs={0} md={3} />
            </Row>
            <Row className="inner middle" style={{ margin: 0 }}>
                <Col xs={0} md={3} />
                <Col xs={0} md={3}>
                    <Row>
                        <h5>
                            We focus on the middle market, where we can best serve the
                            interests of all our stakeholders, including residents and investors.
                        </h5>
                    </Row>
                </Col>
                <Col md={6} style={{ textAlign: 'left' }}>
                    <Row>
                        <Col md={10}>
                            <img src={investor3} style={{ maxWidth: 'inherit' }} />
                        </Col>
                        <Col xs={0} md={2}></Col>
                    </Row>
                </Col>
            </Row>
            <Row className="section-row" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col xs={0} md={2}></Col>
                        <Col md={10}>
                            <img src={investor6} style={{ maxWidth: 'inherit' }} />
                        </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <Row>
                        <h5>
                            Our investments allow a better quality of life for residents,
                            foster more vibrant communities, and drive investment returns.
                        </h5>
                    </Row>
                </Col>
                <Col xs={0} md={3} />
            </Row>
            <Row className="section-row" style={{ margin: 0 }}>
                <Col md={6} style={{ marginBottom: '50px' }}>
                    <Row>
                        <h2>In the community.</h2>
                    </Row>
                    <Row>
                        <p>
                            HomeSource targets markets throughout the Southeast that present transaction volume at
                            attractive yields, with concentration to maximize operational efficiency.
                        </p>
                    </Row>
                    <Row>
                        <p>
                            Investments include both newly built and existing SFR properties in neighborhoods with high ownership rates,
                            desirable schools and proximity to major employment centers. Later expansion is
                            expected into other regional markets with attractive value opportunities.
                        </p>
                    </Row>
                    <Row>
                    </Row>
                </Col>
                <Col md={1} />
                <Col md={4}>
                    <img src={investor7} style={{ maxWidth: 'inherit' }} />
                </Col>
                <Col md={1} />
            </Row>
        </Container>

    )
}

export default Investors;