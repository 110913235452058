import "./AboutUs.css"
import { Container, Row, Col, Button } from "react-bootstrap";
import about1 from '../../images/HS-about-banner-min-e1649686863430.png';
import about2 from '../../images/HS-about-vector1-min.png';
import about3 from '../../images/HS-about-vector2-min.png';
import about4 from '../../images/HS-about-vector3-min.png';
import about5 from '../../images/HS-about-vector4-min.png';
import about6 from '../../images/pexels-kampus-production-6299265.jpg';
import about7 from '../../images/HS-about-community-min.png';
import about8 from '../../images/HS-about-amenities-min.png';
import about9 from '../../images/HS-about-footerbg-min.png';
import { Link } from "react-router-dom";

function AboutUs() {
    return (
        <Container fluid style={{ padding: 0, flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row className="section-row" style={{ margin: 0 }}>
                <Col lg={3} />
                <Col lg={6}>
                    <Row>
                        <h2 style={{ textAlign: 'center' }}>Exceptional Experiences.</h2>
                        <p style={{ textAlign: 'center' }}>
                            HomeSource was founded in 2019 and has grown to include over 3,500 single-family residential homes.
                            From the very beginning, we have focused on developing a portfolio of high-quality homes in some of
                            the most desirable neighborhoods across the Southeastern United States. We are invested in setting the
                        </p>
                    </Row>
                </Col>
                <Col lg={6} />
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col xs={0} md={4} />
                <Col md={4} id="center-img">
                    <h2 className="special">Find Peace of Mind.</h2>
                    <p className="special">
                        When a home is warm and welcoming, you feel it the moment you walk through the front door.
                        Let us help you make your next house a home.
                    </p>
                </Col>
                <Col xs={0} md={4} />
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col md={4}>
                    <hr />
                    <Row><h5>Available 24/7</h5></Row>
                    <Row><img className="img-center" src={about3} /></Row>
                </Col>
                <Col md={4}>
                    <hr />
                    <Row><h5>Timely Service</h5></Row>
                    <Row><img className="img-center" src={about4} /></Row>
                </Col>
                <Col md={4}>
                    <hr />
                    <Row><h5>Specialized Knowledge</h5></Row>
                    <Row><img className="img-center" src={about5} /></Row>
                </Col>
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                {/* <Col xs={0} md={5} style={{ marginBottom: '50px' }}>
                    <Row>
                        <h2>In Your Community</h2>
                    </Row>
                    <Row>
                        <p>We take pride in investing in and supporting our communities. Experience life beyond four walls.</p>
                    </Row>
                </Col> */}
                {/* <Col xs={0} md={1} />
                <Col md={6} style={{ textAlign: 'left' }} /> */}
                <h2 style={{ textAlign: 'center' }}>In Your Community</h2>
                <p style={{ textAlign: 'center' }}>We take pride in investing in and supporting our communities. Experience life beyond four walls.</p>
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col md={2} />
                        <Col md={10}>
                            <img className="content-img" src={about6} style={{ maxWidth: 'inherit' }} />
                        </Col>
                    </Row>

                </Col>
                <Col md={2}>
                    <Row>
                        <h3>ACCESSIBLE GREEN SPACES</h3>
                    </Row>
                </Col>
                <Col xs={0} md={4} />
            </Row>
            <Row className="inner middle" style={{ margin: 0 }}>
                <Col xs={0} md={4} />
                <Col xs={0} md={2}>
                    <Row>
                        <h3>COMMUNITY AND CULTURE</h3>
                    </Row>
                </Col>
                <Col md={6} style={{ textAlign: 'left' }}>
                    <Row>
                        <Col md={10}>
                            <img className="content-img" src={about7} style={{ maxWidth: 'inherit' }} />
                        </Col>
                        <Col xs={0} md={2}></Col>
                    </Row>
                    {/* <img src={about7} style={{maxWidth: 'inherit'}}/> */}
                </Col>
            </Row>
            <Row className="section-row" style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col xs={0} md={2}></Col>
                        <Col md={10}>
                            <img className="content-img" src={about8} style={{ maxWidth: 'inherit' }} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <Row>
                        <h3>CLOSE TO MAJOR AMENITIES</h3>
                    </Row>
                </Col>
                <Col xs={0} md={4} />
            </Row>
            <Row id="bottom-img">
                <img src={about9} alt="footer image" />
                <div className="overlay-bottom">
                    <h4>Investment Opportunities</h4>
                    <h2>Find out how you can invest in the future of single-family rental real estate.</h2>
                    <Button size="lg" as={Link} to="/investors" id="investors-btn">Learn More</Button>
                </div>
            </Row>
        </Container>
    )
}

export default AboutUs;