import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import pet1 from '../../images/erda-estremera-sxNt9g77PE0-unsplash.jpg';
import pet2 from '../../images/HS-pet-dogs-min.png';
import pet3 from '../../images/HS-pet-cats-min.png';
import pet4 from '../../images/HS-pet-birds-min.png';
import pet5 from '../../images/HS-pet-service-min.png';
import pet6 from '../../images/HS-smile-min.png';

function PetPolicy() {

    return (
        <Container fluid style={{ padding: 0, marginTop: '56px', flexDirection: 'column', backgroundColor: '#F5EEDF' }}>
            <Row id="pet-img">
                <img src={pet1} alt="header image" />
                <span className="overlay"><h1>Pet Policy.</h1></span>
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col xs={0} lg={4} />
                <Col lg={4} id="center-img">
                    <h2 className="special">A part of the family.</h2>
                    <p className="special">
                        Where communities and properties allow — and with a few exceptions —
                        we are happy to welcome your pets for an additional monthly fee.
                    </p>
                </Col>
                <Col xs={0} lg={4} />
            </Row>
            <Row className="inner" style={{ margin: 0 }}>
                <Col xs={0} md={4} />
                <Col md={4} id="center-img">
                    <h2 className="special">Which pets qualify?</h2>
                </Col>
                <Col xs={0} md={4} />
            </Row>
            <Row className="inner">
                <Col md={4} style={{display: 'flex', flexDirection: 'column'}}>
                    <Row><h5>DOGS</h5></Row>
                    <Row><p className="special">With the exception of the breeds listed below.</p></Row>
                    <Row><img className="img-center" src={pet2} /></Row>
                </Col>
                <Col md={4}>
                    <Row><h5>CATS</h5></Row>
                    <Row><p className="special">All breeds of domestic cats are permitted.</p></Row>
                    <Row><img className="img-center" src={pet3} /></Row>
                </Col>
                <Col md={4}>
                    <Row><h5>BIRDS</h5></Row>
                    <Row><p className="special">Domestic birds, such as cockatiels and parakeets, are permitted.</p></Row>
                    <Row><img className="img-center" src={pet4} /></Row>
                </Col>
            </Row>
            <Row className="section-row">
                <Col md={4} />
                <Col md={4}>
                    <h5>
                        PLEASE PROVIDE YOUR PET'S VETERINARY RECORDS AND PHOTOS AT THE TIME OF APPLICATION.
                    </h5>
                </Col>
                <Col md={4} />
            </Row>
            <Row className="section-row" style={{ backgroundColor: '#dfece4', margin: 0 }}>
                <Col md={6} style={{ marginBottom: '50px' }}>
                    <Row>
                            <h2>
                                Service and companion animals are always welcome with the appropriate documentation,
                                and are not subject to the pet policy fee.
                            </h2>
                    </Row>
                </Col>
                <Col md={4}>
                    <img src={pet5} style={{ maxWidth: 'inherit' }} />
                </Col>
            </Row>
            <Row className="inner">
                <Col md={4} />
                <Col md={4}>
                    <Row>
                        <h2>Prohibited Pets.</h2>
                    </Row>
                    <Row>
                        <h5>
                            Our homeowner insurance policy limits the types of pets we can accommodate.
                            We cannot approve barnyard animals, reptiles, rodents, fish, miscellaneous mammals,
                            and certain types of dog breeds:
                        </h5>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Akita</li>
                                <li>Chow</li>
                                <li>Pit Bull</li>
                                <li>Bull Terrier</li>
                                <li>Pit Bull Terrier</li>
                                <li>Alaskan Malamute</li>
                                <li>Doberman</li>
                                <li>Siberian Husky</li>
                                <li>Can Corso</li>
                            </ul>
                        </Col>
                        <Col md={6}>
                            <ul style={{ textAlign: 'left' }}>
                                <li>German Shepard</li>
                                <li>Boxer</li>
                                <li>Husky</li>
                                <li>Staffordshire Terrir</li>
                                <li>Rottweiler</li>
                                <li>Bullmastiff or Mastiff</li>
                                <li>Presa Canario</li>
                                <li>Wolf/Dog Hybrids</li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} />
            </Row>
            <Row className="section-row">
                <Col md={4} />
                <Col md={4} style={{ textAlign: 'center' }}>
                    <Row>
                        <img src={pet6} style={{ width: 'inherit' }} />
                    </Row>
                    <Row>
                        <h2 className="special">Sound like a fit?</h2>
                    </Row>
                    <Row><h5>THE ONLINE APPLICATION PROCESS IS SIMPLE - AND, MOBILE FRIENDLY.</h5></Row>
                    <Row><p className="special">Follow the link below to view properties and apply online.</p></Row>
                    <Row>
                        <Button style={{ backgroundColor: '#fa9370', border: 'none' }} as={Link} to="/available-properties">View Available Properties</Button>
                    </Row>
                </Col>
                <Col md={4} />
            </Row>
        </Container>
    )
}

export default PetPolicy;