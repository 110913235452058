import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import allStates from "./statesData";
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Box, Slider, Checkbox, FormGroup, FormControlLabel, FormControl } from "@mui/material";



function PropertyFilters({ setBaths, baths, beds, setBeds, setQuery, properties, setFiltered, setStates, states, filtered, value, setValue, rentValue, setRentValue, clearFilters }) {

    const [statesArray, setStatesArray] = useState([]);
    const [checkedState, setCheckedState] = useState([]);

    const setArray = () => {
        allStates.forEach(state => {
            properties.forEach(property => {
                if (property.StateAbbr === state) {
                    if (statesArray.indexOf(property.StateAbbr) === -1) {
                        setStatesArray([...statesArray, state]);
                        setCheckedState([...checkedState, true]);
                    }
                }
            });
        });
    }

    useEffect(() => {
        if (filtered === false) {
            setStates(statesArray);
        }
        setArray();
    })

    const bedMarks = [
        {
            value: 1,
            label: '1+',
        },
        {
            value: 2,
            label: '2+',
        },
        {
            value: 3,
            label: '3+',
        },
        {
            value: 4,
            label: '4+',
        },
        {
            value: 5,
            label: '5+'
        },
    ];

    const bathMarks = [
        {
            value: 1,
            label: '1+',
        },
        {
            value: 2,
            label: '2+',
        },
        {
            value: 3,
            label: '3+',
        },
        {
            value: 4,
            label: '4+',
        },
    ];

    const footMarks = [
        {
            value: 0,
            label: '0'
        },
        {
            value: 10000,
            label: '10,000'
        }
    ];

    const rentMarks = [
        {
            value: 1000,
            label: '$1,000'
        },
        {
            value: 4000,
            label: '$4,000'
        }
    ];

    const handleOnChange = (position, event, state) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        if (event.target.checked === true) {
            setStates([...states, state]);
        } else {
            setStates(states.filter(element => element !== state))
        }
        setFiltered(true);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setFiltered(true);
    };

    const handleRentChange = (event, newValue) => {
        setRentValue(newValue);
        setFiltered(true);
    };

    const handleClear = () => {
        clearFilters();
        const updatedCheckedState = new Array(statesArray.length).fill(true);
        console.log(updatedCheckedState);
        setCheckedState(updatedCheckedState);
        setFiltered(false);
    }

    return (
        <div>
            <Row className="filter-row">
                <Col id="filters" style={{ display: 'flex', paddingLeft: 0, gap: '1rem', marginTop: '1rem' }}>
                    <Button id="clear-button" style={{ whiteSpace: 'nowrap', borderRadius: '5px' }} onClick={handleClear}>Clear Filters</Button>
                    <DropdownButton className="filter-button" title="States">
                        <FormControl>
                            <FormGroup>
                                {
                                    statesArray.map((state, index) => (
                                        <FormControlLabel key={index}
                                            control={<Checkbox
                                                className="state-boxes"
                                                sx={{
                                                    color: '#fa9370',
                                                    '&.Mui-checked': {
                                                        color: '#fa9370'
                                                    },
                                                    paddingLeft: '20px'
                                                }}
                                                value={state}
                                                // defaultChecked
                                                checked={checkedState[index]}
                                                onChange={event => handleOnChange(index, event, state)} />}
                                            label={state}
                                        />
                                    ))
                                }
                            </FormGroup>
                        </FormControl>
                    </DropdownButton>
                    <DropdownButton className="filter-button" title="Bedrooms">
                        <Box sx={{ width: 300 }} style={{ padding: '0 20px' }}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                valueLabelDisplay="auto"
                                defaultValue={1}
                                value={beds}
                                min={1}
                                max={5}
                                step={1}
                                marks={bedMarks}
                                sx={{ color: '#fa9370' }}
                                onChange={event => {
                                    setBeds(event.target.value);
                                    setFiltered(true);
                                }}
                            />
                        </Box>
                    </DropdownButton>
                    <DropdownButton className="filter-button" title="Bathrooms">
                        <Box sx={{ width: 300 }} style={{ padding: '0 20px' }}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                valueLabelDisplay="auto"
                                id="bathroom-slider"
                                defaultValue={1}
                                value={baths}
                                min={1}
                                max={4}
                                step={1}
                                marks={bathMarks}
                                sx={{ color: '#fa9370' }}
                                onChange={event => {
                                    setBaths(event.target.value);
                                    setFiltered(true);
                                }}
                            />
                        </Box>
                    </DropdownButton>
                    <DropdownButton className="filter-button" title="Square Footage">
                        <Box sx={{ width: 300 }} style={{ padding: '0 30px' }}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                valueLabelDisplay="auto"
                                min={0}
                                max={10000}
                                step={500}
                                value={value}
                                sx={{ color: '#fa9370' }}
                                marks={footMarks}
                                onChange={handleChange}
                            />
                        </Box>
                    </DropdownButton>
                    <DropdownButton className="filter-button" title="Monthly Rent">
                        <Box sx={{ width: 300 }} style={{ padding: '0 30px' }}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                valueLabelDisplay="auto"
                                min={1000}
                                max={4000}
                                step={500}
                                value={rentValue}
                                marks={rentMarks}
                                sx={{ color: '#fa9370' }}
                                onChange={handleRentChange}
                            />
                        </Box>
                    </DropdownButton>
                    <Form.Group style={{ textAlign: 'center', color: '#003A40', width: '100%' }}>
                        <Form.Control
                            style={{ backgroundColor: '#F5EEDF' }}
                            type="text"
                            placeholder="Street address, city, state, or zip"
                            onChange={event => {
                                setQuery(event.target.value.toLowerCase());
                                setFiltered(true);
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </div >
    )
}

export default PropertyFilters;