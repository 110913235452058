import { Button } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useRef } from "react";
import { Map, MapLayers, MapTileLayer, MapMarkerLayer } from '@progress/kendo-react-map';
import Iframe from "react-iframe";
import Redirect from "../../components/Redirect/Redirect";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from "@mui/material";
import { Carousel, Image } from "react-bootstrap";
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MdStairs } from 'react-icons/md';
import { BiBed, BiBath, BiBuildingHouse } from 'react-icons/bi';
import { TbRulerMeasure } from 'react-icons/tb';
import { BsCurrencyDollar, BsCalendarDate } from 'react-icons/bs';
import { PiGarageDuotone } from 'react-icons/pi';
import { LiaHammerSolid } from 'react-icons/lia';

function PropertyDetails() {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('');
    const property = useSelector(store => store.details);
    const photos = useSelector(store => store.photos);
    const tileUrl = (e) => `https://tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;
    const center = [property.Lat, property.Long];
    const ref = useRef(null);
    const tourUrl = `https://www.insidemaps.com/app/walkthrough-v2/?projectId=${property.projectId}&env=production&embedded=true`

    const markers = [
        {
            latlng: [property.Lat, property.Long],
            name: property.Street
        }
    ]
    const items = [];

    for (let i of photos) {
        items.push({
            position: photos.indexOf(i),
            url: i
        });
    }

    function handleClick() {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const displayModal = option => {
        option === 'tour' ? setUrl(`https://homesource.behome247.com/app/tours/schedule/${property.HSPropertyId}`) : setUrl('https://homesource.behome247.com/app/tours/search');
        setShow(true);
    }

    return (
        <Container fluid style={{ padding: 0, marginTop: '56px' }}>
            <Row ref={ref} className="section-row" style={{ display: 'flex', flexWrap: 'wrap', margin: 0 }}>
                <h2 style={{ textAlign: 'center' }}>{property.Street} {property.CityName}, {property.StateAbbr} {property.Zip}</h2>
            </Row>
            <Row id="details-row">
                <Col lg={1} />
                <Col lg={10}>
                    <Row style={{ borderRadius: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                        <Col lg={8} style={{ padding: 0 }}>
                            <Carousel interval={null} style={{ borderRadius: '20px' }}>
                                {
                                    items.map((item, index) => {
                                        return (
                                            <Carousel.Item className="img-cont" style={{ borderRadius: '20px' }}>
                                                <Image style={{ height: '60vh', objectFit: 'cover', borderRadius: '20px' }} fluid src={item.url} />
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '20px' }}>
                            <Row>
                                <h2 style={{ textAlign: 'center' }}>Details</h2>
                            </Row>
                            <Row>
                                {/* <Col lg={3} /> */}
                                <Col lg={6}>
                                    <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                        <li className="details-list"><BsCurrencyDollar /> {property.AdvertisedRent}/month</li>
                                        <li className="details-list"><BiBed />{property.Beds} beds</li>
                                        <li className="details-list"><PiGarageDuotone /> {property.Garage} Car Garage</li>
                                        <li className="details-list"><BiBuildingHouse /> {property.PropertyType}</li>
                                    </ul>
                                </Col>
                                {/* <Col lg={3} /> */}
                                <Col lg={6}>
                                    <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                        <li className="details-list"><BiBath /> {property.Baths} baths</li>
                                        <li className="details-list"><TbRulerMeasure /> {property.Sqft} SqFt</li>
                                        <li className="details-list"><LiaHammerSolid /> Built in {property.YearBuilt}</li>
                                        <li className="details-list"><BsCalendarDate /> {property.MarketingDate}</li>
                                    </ul>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col lg={6}>
                                    <ul style={{ listStyle: 'none', textAlign: 'center', paddingLeft: 0 }}>
                                        <li><PiGarageDuotone /> {property.Garage} Car Garage</li>
                                        <li><BiBuildingHouse /> {property.PropertyType}</li>
                                    </ul>
                                </Col>
                                <Col lg={6}>
                                    <ul style={{ listStyle: 'none', textAlign: 'center', paddingLeft: 0 }}>
                                        <li><LiaHammerSolid /> Built in {property.YearBuilt}</li>
                                        <li><BsCalendarDate /> Listed on {property.MarketingDate}</li>
                                    </ul>
                                </Col>
                            </Row> */}
                            <Row style={{ padding: '20px 0' }}>
                                <div style={{ textAlign: 'center', padding: 0 }}>
                                    <Button
                                        style={{ backgroundColor: '#fa9370', border: 'none', marginRight: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                                        onClick={() => displayModal('tour')}
                                    >
                                        Schedule Tour
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#fa9370', border: 'none', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                                        onClick={() => displayModal('apply')}
                                    >
                                        Apply Now
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                        {/* <Col lg={6} className="hide-mobile" style={{ paddingRight: 0 }}>
                            <Map center={center} zoom={5} style={{ paddingBottom: '20px' }} >
                                <MapLayers>
                                    <MapTileLayer
                                        urlTemplate={tileUrl}
                                        attribution='© OpenStreetMap contributors'
                                    />
                                    <MapMarkerLayer
                                        data={markers}
                                        locationField="latlng"
                                        titleField="name"
                                    />
                                </MapLayers>
                            </Map>
                        </Col> */}
                    </Row>
                    <Row>
                        <Map className="hide-mobile" center={center} zoom={5} style={{ padding: 0, margin: '5rem 0' }} >
                            <MapLayers>
                                <MapTileLayer
                                    urlTemplate={tileUrl}
                                    attribution='© OpenStreetMap contributors'
                                />
                                <MapMarkerLayer
                                    data={markers}
                                    locationField="latlng"
                                    titleField="name"
                                />
                            </MapLayers>
                        </Map>
                    </Row>
                    {/* <Row>
                        <h2 style={{ textAlign: 'center' }}>Details</h2>
                        <Col lg={3} style={{ paddingLeft: 0 }}>
                            <ul style={{ listStyle: 'none', textAlign: 'left', paddingLeft: 0 }}>
                                <li><BsCurrencyDollar /> {property.AdvertisedRent}/month</li>
                                <li><BiBed /> {property.Beds} beds</li>
                            </ul>
                        </Col>
                        <Col lg={3} style={{ paddingLeft: 0 }}>
                            <ul style={{ listStyle: 'none', textAlign: 'left', paddingLeft: 0 }}>
                                <li><BiBath /> {property.Baths} baths</li>
                                <li><TbRulerMeasure /> {property.Sqft} SqFt</li>
                            </ul>
                        </Col>
                        <Col lg={3} style={{ paddingLeft: 0 }}>
                            <ul style={{ listStyle: 'none', textAlign: 'left', paddingLeft: 0 }}>
                                <li><PiGarageDuotone /> {property.Garage} Car Garage</li>
                                <li><BiBuildingHouse /> {property.PropertyType}</li>
                            </ul>
                        </Col>
                        <Col lg={3} style={{ paddingLeft: 0 }}>
                            <ul style={{ listStyle: 'none', textAlign: 'left', paddingLeft: 0 }}>
                                <li><LiaHammerSolid /> Built in {property.YearBuilt}</li>
                                <li><BsCalendarDate /> Listed on {property.MarketingDate}</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row style={{ padding: '50px 0' }}>
                        <div style={{ textAlign: 'left', padding: 0 }}>
                            <Button
                                style={{ backgroundColor: '#fa9370', border: 'none', marginRight: '5px' }}
                                onClick={() => displayModal('tour')}
                            >
                                Schedule Tour
                            </Button>
                            <Button
                                style={{ backgroundColor: '#fa9370', border: 'none' }}
                                onClick={() => displayModal('apply')}
                            >
                                Apply Now
                            </Button>
                        </div>
                    </Row> */}
                    <Row className="hide-mobile">
                        <h2 style={{ textAlign: 'center', paddingBottom: '20px' }}>3D Tour</h2>
                    </Row>
                    <Row style={{ height: '80vh', paddingBottom: '50px' }} className="hide-mobile">
                        <Iframe
                            id="tour"
                            src={tourUrl}
                            width="100%"
                            height="100%"
                            allowfullscreen
                        />
                    </Row>
                </Col>
                <Col lg={1} />
            </Row>
            <Redirect
                show={show}
                setShow={setShow}
                url={url}
            />
        </Container>
    )
}




export default PropertyDetails;